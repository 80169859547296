<template>
  <k-combobox
    :items="expertises"
    :value="value"
    @input="$emit('input', $event); autocompleteInput = null"
    v-bind="$attrs"
    :sub-title="subTitle"
    :search-input.sync="autocompleteInput"
    small-chips
    deletable-chips
    multiple
    item-text="name"
    item-value="expertiseId"
    class="k-autocomplete-technologies"
    :disabled="disabled"
  >
    <template v-slot:selection="data">
      <v-chip
        class="chip"
        v-bind="data.attrs"
        :input-value="data.selected"
        :close="!disabled"
        @click="data.select"
        @click:close="remove(data.item)"
        :key="data.item.id"
        :disabled="disabled"
      >
                <span class="chip__content">
                    {{ data.item.name }}
                </span>
      </v-chip>
    </template>

    <template v-slot:prepend-item>
      <slot name="prepend-item" />
    </template>

    <template #message="{ message }">
      {{ $t(message) }}
    </template>
  </k-combobox>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showLimit: {
      type: Number,
      default: 5
    },
    subTitle: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  data() {
    return {
      autocompleteInput: null
    }
  },
  computed: {
    ...mapGetters('ENUMS', {
      expertises: 'getExpertises'
    })
  },
  methods: {
    remove(item) {
      this.$emit('input', this.value.filter(v => v.expertiseId !== item.expertiseId))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.k-autocomplete-technologies ::v-deep .k-input.v-input .v-input__slot {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0.25rem !important;
}

::v-deep .k-input.v-input {
  .v-input__slot {
    padding: 0 !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}

::v-deep .v-select__selections {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

::v-deep .chip .v-chip__close {
  .wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: nowrap !important;
    background-color: #f1f0ee !important;
    border-radius: 9999px !important;
    border: none !important;
    margin-right: 0.025rem !important;
    transition: all 250ms ease-in-out;

    svg {
      width: 0.75rem !important;
      height: 0.75rem !important;

      * {
        transition: all 250ms ease-in-out;
        fill: black;
      }
    }

    &:hover {
      background-color: black !important;

      svg {
        * {
          fill: white;
        }
      }
    }
  }
}

.chip {
  font-size: 0.875rem;
  line-height: 1.6;
  color: $koderia-gray-900;
  border-radius: 0.25rem;
  background-color: white !important;
  border: 1px solid $koderia-gray-100 !important;
  padding: 0.125rem 0.5rem;

  &:hover {
    &::before {
      opacity: 0 !important;
    }
  }
}

::v-deep .chip button.v-icon__component {
  width: 14px !important;
  height: 14px !important;
  font-size: 14px !important;
}

.chip__content {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 6rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: monospace;
  font-size: 0.875rem !important;
  line-height: 1.6;
}

.has-more {
  padding-left: 0.25rem;
  color: $koderia-black;
}
</style>
