<script>
import { VCombobox } from "vuetify/lib";

export default {
    extends: VCombobox,
    props: {
        // Custom
        invalid: {
            type: Boolean,
            default: false
        },

        title: {
            type: String
        },

        subTitle: {
            type: String
        },

        // Vuetify
        flat: {
            type: Boolean,
            default: true
        },
        filled: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: true
        },
        hideDetails: {
            type: [Boolean, String],
            default: "auto"
        },
        menuProps: {
            default: () => ({
                contentClass: 'content-class',
            })
        }
    },
    computed: {
        classes() {
            return {
                "k-input": true,
                "k-input--invalid": this.invalid,
                ...VCombobox.options.computed.classes.call(this)
            };
        }
    },

    methods: {
        /**
         * Do not render default vuetify label
         */
        genLabel() {
            return null;
        },

        genCustomLabel() {
            if (this.$slots.label) {
                return this.$slots.label;
            }

            if (this.label || this.title) {
                const isRequired = this.$attrs.required === '' || this.$attrs.required === true
                let labelClass = isRequired ? "k-input__label k-input-required" : "k-input__label"

                if (this.subTitle) {
                    labelClass += " k-input__label-small"
                }

                const label = { staticClass: labelClass, attrs: { for: this.computedId } };
                const subLabel = { staticClass: "k-input_sub-label" }
                const element = this.subTitle ? this.$createElement("div", subLabel, this.subTitle) : undefined
                const children = [this.label || this.title, element]

                return this.$slots.label || this.$createElement("label", label, children);
            }

            return null;
        },

        /**
         * Render the default vuetify input see render() in `/vuetify/lib/components/VInput/VInput.js`
         */
        genBaseInput() {
            return this.$createElement(
                "div",
                this.setTextColor(this.validationState, {
                    staticClass: "v-input",
                    class: this.classes,
                }),
                this.genContent()
            );
        }
    },
    render(h) {
        return h("div", {}, [this.genCustomLabel(), this.genBaseInput()]);
    }
};
</script>

<style lang="scss" scoped>
.k-input__label {
    font-size: 0.875rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
    display: inline-block;
    color: $koderia-gray-800;
}

.k-input__label-small {
    padding-bottom: 0.25rem !important;
}

.k-input_sub-label {
    font-size: 0.75rem;
    line-height: 1.75rem;
    color: $koderia-gray-500;
    font-weight: normal;
}

.k-input-required {
    &::after {
        content: "*";
        color: $koderia-red;
        margin-left: 0.25rem;
    }
}

.k-input.v-input {
    * {
        transition-property: all;
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }

    .v-label {
        color: #999999 !important;
    }

    .v-messages.error--text {
        color: #eb3300;
    }

    input,
    .v-label {
        margin: 0 !important;
        padding: 0 !important;
    }

    &.k-input--invalid,
    &.error--text {
        .v-input__slot {
            background: #fff !important;
            box-shadow: 0 0 0 4px #eb330033 !important;
            border: 1px solid #eb3300 !important;
        }
    }

    &.v-input--is-focused {
        .v-input__slot {
            background: #fff !important;
            border: 1px solid #1f2937 !important;
            box-shadow: 0 0 0 4px #f3f4f6 !important;
        }
    }

    &.v-input--is-disabled {
        .v-input__slot {
            background: #fff !important;
            border: 1px solid #d1d5db !important;
        }
    }

    &:not(.v-input--is-focused) {
        .v-input__slot:hover {
            background: #fff !important;
            border: 1px solid #d1d5db !important;
            box-shadow: 0 0 0 4px #f3f4f6 !important;
        }
    }

    .v-input__slot {
        padding: 0.625rem 0.875rem !important;
        background: #f3f4f6 !important;
        border: 1px solid #f3f4f6 !important;
        border-radius: 0.375rem !important;
        min-height: 2.875rem !important;
    }

    .v-input__control .v-select__slot .v-input__icon {
        margin-right: -0.5rem !important;
    }
}

.content-class {
    border-radius: 1rem !important;
    box-shadow: 0 0 20px -5px rgb(17 24 39 / 5%) !important;
    z-index: 10 !important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.content-class::-webkit-scrollbar {
    display: none;
}

.content-class {
    ::v-deep .v-list .v-list-item {
        color: $koderia-gray-800 !important;

        &:hover {
            background-color: $koderia-white !important;
            color: $koderia-gray-500 !important;
        }
    }

    ::v-deep .v-list .v-list-item--active {
        font-weight: bolder;
        color: $koderia-green !important;
        background-color: $koderia-white !important;

        &:hover {
            background-color: $koderia-white !important;
            color: $koderia-green-dark !important;
        }
    }
}
</style>
